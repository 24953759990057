import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField,} from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Footer from '../../Components/Footer/Footer';
import DemoImage from '../../Assets/HomePage_2nd/banner-4.png'
import FetaureImage from '../../Assets/Frame_31612501.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Worktestimonials, teamsTestimonials, teamscompose } from '../../Variables/ProcessVariable';
import Avatar1 from '../../Assets/Testimonials/testi_avatar01.png';
import Avatar2 from '../../Assets/Testimonials/testi_avatar02.png';
import Avatar3 from '../../Assets/Testimonials/testi_avatar03.png';
import fileLogo from '../../Assets/Testimonials/testi_avatar03.png';
import { get_teams_business1 , get_teams_business2 , get_teams_business3 , get_teams_business4 } from '../../API Service/Api';
import axios from 'axios';


export default function Teams() {

    const settings1 = {
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        };
        
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("");
        const [status, setStatus] = useState();
        const [color, setColor] = useState();
        const [Teams1 , setTeams1] = useState([]);
        const [Teams2 , setTeams2] = useState([]);
        const [Teams3 , setTeams3] = useState([]);
        const [Teams4 , setTeams4] = useState([]);
        

        const getTeams1 = () => {
          const sendData = new FormData()
          sendData.append('teamsBusinessId1', '');
          axios({
            method: 'POST',
            url: get_teams_business1,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setTeams1(res.data.data[0])
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        const getTeams2 = () => {
          const sendData = new FormData()
          sendData.append('teamsBusinessId2', '');
          axios({
            method: 'POST',
            url: get_teams_business2,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setTeams2(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        const getTeams3 = () => {
          const sendData = new FormData()
          sendData.append('teamsBusinessId3', '');
          axios({
            method: 'POST',
            url: get_teams_business3,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setTeams3(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }

        
        const getTeams4 = () => {
          const sendData = new FormData()
          sendData.append('teamsBusinessId4', '');
          axios({
            method: 'POST',
            url: get_teams_business4,
            data:sendData
          }).then(res => {
            if (!res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(true)
              setColor(true)
              setTeams4(res.data.data)
            } else {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage(res.data.message)
            }
          }).catch(err => {
            alert('Oops something went wrong ' + err)
          });
        }
      
        useEffect(() => {
          getTeams1();
        }, []);

        useEffect(() => {
          getTeams2();
        }, []);

        useEffect(() => {
          getTeams3();
        }, []);

        useEffect(() => {
          getTeams4();
        }, []);


        const Feature = ({ title, content, image, index}) => (
          <>
          {
           index % 2 === 0 ?
           <Grid container spacing={3}>
           <Grid item xs={12} md={6} sm={6} lg={6}  
           order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
           display='flex' justifyContent='center' alignItems='center' alignContent='center'
           > 
           <Stack textAlign='left' spacing={2}>
           <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
           <Typography variant='subtitle1' fontWeight={550} className='content'>
           {content}
           </Typography>            
           </Stack>
           </Grid>
   
           <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} display='flex'  justifyContent='center'>
           <Box>
           <img src={image} alt='feature' className='featureImage' />
           </Box>
           </Grid>
           </Grid>
             :
       
             <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
             <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:1 , md:1 , sm: 1 , xs: 1}} display='flex' justifyContent='center'>
             <Box>
             <img src={image} alt='feature' className='featureImage' />
             </Box>
             </Grid>
     
             <Grid item xs={12} md={6} sm={6} lg={6}  
             order={{lg:2 , md:2 , sm: 2 , xs: 2}} 
             display='flex' justifyContent='center' id='rewrite'
             >
             <Stack textAlign='left' spacing={2}>
             <Typography variant='h3' fontWeight={600} className='title'>{title}</Typography>
             <Typography variant='subtitle1' fontWeight={550} className='content'>
            {content}
             </Typography>            
             </Stack>
             </Grid>
             </Grid>
          }
          </>
         );

  return (
    <Box className='Banner'>
        <Header />

       <Container>
        <Box mt={8}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
        <Box textAlign='left'>
        <Typography variant='h4' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>{Teams1.title}</Typography>
        <Typography lineHeight={1.5} variant='subtitle1' mb='20px'>
        {Teams1.content}
        </Typography>
        </Box>
        <Box flexDirection='row' gap={5} display='flex' justifyContent='start'>
        <Button variant='contained'        
        sx={{bgcolor:'#135DA8' ,width:'15ch', fontSize:'18px' , color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}}>
         Get Quote
        </Button>
        <Button variant='contained'  sx={{bgcolor:'#CECECE' , width:'15ch', fontSize:'18px' , color:'#000' ,fontWeight:600 , textTransform : 'capitalize', ':hover':{bgcolor:'#CECECE'}}}>
        Contact Sales
        </Button> 
        </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} position='relative' mt={{lg:0, md:0 , sm:3 , xs:4}}>
        <Box>
        <img src={Teams1.images} alt='demo' className='demoImage' />
        </Box>
        </Grid>

        </Grid>   
        </Box>
        </Container>


        <Container>
       <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
       <Typography variant='h2' className='Banner2Title'>
       Accelerate Information Flow by Connecting Silos
       </Typography>

       <Stack spacing={3}  mt={5}>

       {Teams2.map((feature, index) => (
            <Feature
              key={index}
              index={index}
              title={feature.title}
              content={feature.content}
              image={feature.images}
            />
          ))}


            </Stack>
      </Box>
      </Container>

        
        

 <Box>


      <Box sx={{backgroundColor:'#135DA8', textAlign:'justify', color:'white'}} mt={5}>
    <Container>
        <Box py={3}>
      <Grid container spacing={8} display='flex' justifyContent='center'> 
        {
          Teams3.map((i)=>{
                return(
                    <Grid item xs={6} sm={6} md={4} lg={4} textAlign='justify'>
                        <Stack spacing={2}>
                            <Box>
                            <img src={i.images} alt="file" width="60" height="60" />
                            </Box>
                          <Typography variant='h6'>{i.title}</Typography>
                         <Typography variant='p' width='70%'>{i.content}</Typography>
                         <Stack spacing={1}>
                         <Typography variant='p'>{i.personName}</Typography>
                         <Typography variant='p' width='70%'>{i.personRole}</Typography>
                         </Stack>
                        </Stack>
                    </Grid>  
                )
            })
        }
        </Grid> 
        </Box>
        </Container>
      </Box>

      
    <Container>
      <Box mt={10}>
      <Grid container spacing={3} display='flex' justifyContent='center'> 
        {
            Teams4.map((i)=>{
                return(
                    <Grid item xs={12} sm={6} md={2} lg={2} textAlign='left'>
                        <Stack spacing={2}>
                        <img src={i.images} alt="file" width="60" height="60" />
                        <Typography variant='h6' fontWeight={600}>{i.title}</Typography>
                        <Typography variant='p' width='70%'>{i.content}</Typography>
                        </Stack>
                    </Grid>  
                )
            })
        }
        </Grid>   
      </Box>
      </Container>

    </Box>

           

        <Footer />
    </Box>
  )
}
