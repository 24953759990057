import React, { useEffect, useRef, useState } from 'react'
import './About.css';
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField,} from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Footer from '../../Components/Footer/Footer';
import Image1 from '../../Assets/team-business-people-stacking-hands.jpg';
import Image2 from '../../Assets/istockphoto-468784170-612x612.jpg';
import { aboutSetus } from '../../Variables/ProcessVariable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { get_pricing ,  get_home_section1,  get_home_section2 ,  get_home_section3, get_testimonial, get_faq, get_ai_generate, get_about_us, get_about_us_image, } from '../../API Service/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function About() {

  const zoomInRef = useRef(null);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const[FAQ , setFAQ] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [subscribeType , setSubscribeType] = useState('Monthly');
  const [productPlan , setProductPlan] = useState('POET');
  const [color, setColor] = useState();
  const [section1 , setSection1] = useState([]);
  const [section2 , setSection2] = useState([]);
  const [section3 , setSection3] = useState([]);
  const [section4 , setSection4] = useState([]);
  const [section5 , setSection5] = useState([]);
  const [section6 , setSection6] = useState([]);
  const [section7 , setSection7] = useState([]);
  const [section8 , setSection8] = useState([]);
  const [section9 , setSection9] = useState([]);
  const [section10 , setSection10] = useState([]);
  const [ImageData1 , setImageData1] = useState([]);
  const [ImageData2 , setImageData2] = useState([]);
  const navigate = useNavigate();

  const togglePanel = (index) => {
    let updatedPanels = [];
    // If the selected panel is already open, close it
    if (expandedPanels.includes(index)) {
      updatedPanels = expandedPanels.filter((item) => item !== index);
    } else {
      // Otherwise, open the selected panel and close others
      updatedPanels = [index];
    }
    setExpandedPanels(updatedPanels);
  };

  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('aboutUsId', '');
    axios({
      method: 'POST',
      url: get_about_us,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setSection1(res.data.data[0])
        setSection2(res.data.data[1])
        setSection3(res.data.data[2])
        setSection4(res.data.data[3])
        setSection5(res.data.data[4])
        setSection6(res.data.data[5])
        setSection7(res.data.data[6])
        setSection8(res.data.data[7])
        setSection9(res.data.data[8])
        setSection10(res.data.data[9])
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  const AboutUs = [{...section4} , {...section5} , {...section6}, {...section7}, {...section8}, {...section9}]

  useEffect(() => {
    getAllData();
  }, []);


  const getAllImageData = () => {
    const sendData = new FormData()
    sendData.append('aboutUsImageId', '');
    axios({
      method: 'POST',
      url: get_about_us_image,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setImageData1(res.data.data[0])
        setImageData2(res.data.data[1])
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllImageData();
  }, []);

  const getFAQ = () => {
    const sendData = new FormData()
    sendData.append('faqId', '');
    axios({
      method: 'POST',
      url: get_faq,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setFAQ(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getFAQ();
  }, []);


  useEffect(() => {
    const animateGrid = (ref, animationClass) => {
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(animationClass);
              observer.unobserve(entry.target);
            }
          });
        });

        observer.observe(ref.current);
      }
    };
    animateGrid(zoomInRef, 'animate-section-zoom');
  }, []);

  
  const handleClickOpenNameEdit = (section , id) => {
    navigate(section);
    setTimeout(() => {
    const element = document.getElementById(`${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 1000);
}; 

  return (
        <Box className='Banner'>
        <Header />

        <Box mt={8} p={3}>
        <Grid container spacing={7}>

        <Grid item xs={12} sm={12} md={6} lg={6} position='relative' mt={{lg:0, md:0 , sm:3 , xs:4}}>
        <Box display='flex' flexDirection='row' gap={2}>
        <img src={Image1} alt='demo' className='AboutdemoImage' />
        <img src={Image2} alt='demo' className='AboutdemoImage1' />
        </Box>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
        <Box textAlign='left'>
        <Typography variant='h6' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>Who We Are</Typography>
        <Typography lineHeight={1.5} variant='h4' fontWeight={600} mb='20px'>
       {section1.title}
        </Typography>

        <Typography lineHeight={1.5} variant='p' mb='20px' textAlign='justify'>
        {section1.content}
        </Typography>
        </Box>

        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}  textAlign='justify'>
          <Typography variant='h6' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>{section2.title}</Typography>
          <Typography textAlign='justify' lineHeight={1.5} variant='p' mb='20px'>
           {section2.content}
        </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}  textAlign='justify'>
          <Typography variant='h6' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>{section3.title}</Typography>
          <Typography textAlign='justify' lineHeight={1.5} variant='p' mb='20px'>
          {section3.content}
        </Typography>
            </Grid>
        </Grid>
        </Grid>
        </Grid>   
        </Box>


        <Box  className='Banner2' textAlign='center'>
      <Typography variant='h3' className='Banner2Title' >What Set us Apart</Typography>
      <Container >
      <Grid container mb={5} spacing={3} mt={4}  ref={zoomInRef}>
      {
      AboutUs.map((i , index)=>{ // Define border colors for even and odd items
      return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
      <Box className='GlassCards'>
      <Typography variant='h3' className='glassTitle'>{i.title}</Typography>
      <Typography variant='body1' className='glassContent'>{i.content}</Typography>
      </Box>
      </Grid>
      )
      })
      }
      </Grid>
      </Container>
      </Box>


      <Box bgcolor='#EEF2FF'>
        <Container>
        <Stack spacing={3} p={4}> 
            <Typography variant='h6' className='Banner2Title' sx={{color:'#262626'}} >{section10.title}</Typography>
            <Typography sx={{color:'#747681'}} >{section10.content}</Typography>
            </Stack>
        </Container>
      </Box>

      <Box mt={{lg:10 , md:7 , sm:5 , xs:3}}  >
        <Container>
        <Box>
        <Typography variant='h3' className='Banner2Title' >Frequently Asked Questions</Typography>
        <Grid container mt={2} spacing={4} justifyContent='space-between'>
        <Grid item xs={12} sm={12} md={5} lg={5} position='relative' display='flex' flexDirection='column' justifyContent='start'>
        <Stack spacing={3} textAlign='left'>
        <Typography variant='p' fontWeight={700} fontSize='48px' textTransform='capitalize' lineHeight='72px'>Any <span className='FAQText1'>Questions?</span></Typography>
        <Typography  variant='p' fontWeight={700} fontSize='40px' textTransform='capitalize' >We Have Answers!</Typography>
        <Typography variant='p' color='#475467' width={250}>Don’t find your answer here? just send us a message for any query.</Typography>
        <Button  onClick={()=>handleClickOpenNameEdit('/#QuoteSection', "QuoteSection")} variant='contained' sx={{ width :'25ch', borderRadius: '10px', fontWeight:600 , marginRight:'15px' , bgcolor:'#135DA8'}}>
         Connect with us
        </Button>
        </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={7}>
        <Box>
        {FAQ.map((faq, index) => (
       <Accordion
       key={index}
       expanded={expandedPanels.includes(index)}
       onChange={() => togglePanel(index)}
       sx={{ boxShadow: 2,
        border: 'none' , 
        background:'#BBC8D7',
        marginBottom:3,
        '&.Mui-expanded': {
         borderLeft: '4px solid #135DA8',
         background:'#fff',
       },
       }} 
   >
       <AccordionSummary 
        sx={{
         color: expandedPanels.includes(index) ? '#135DA8' : 'inherit',
        
       }}
       >
          <Box display='flex' justifyContent='space-between' width='100%'>
          <Typography variant='h6' fontWeight={550} className='acccordTitle'>
          {faq.title}
          </Typography>
          <ExpandMoreIcon sx={{fontSize:'40px' , verticalAlign:'top', my:-0.5}} />
          </Box>
       </AccordionSummary>
       <AccordionDetails>
           <Box textAlign='left' px={8}>
               <ul>
                   <Stack spacing={1} mt={1}>
                       <Typography component='p' sx={{ color:'#475467'}}>{faq.content}</Typography>
                   </Stack>
               </ul>
           </Box>
       </AccordionDetails>
   </Accordion>
        ))}


        </Box>
        </Grid>

        </Grid>
        </Box>
        </Container>

        </Box>
  


        <Footer />
        </Box>
  )
}
