import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent, TextField,} from '@mui/material'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import './HomePage.css';
import DemoImage from '../../Assets/HomePage_2nd/banner-4.png'
import FloatImage1 from '../../Assets/HomePage_2nd/banner-3-1.png'
import FloatImage2 from '../../Assets/HomePage_2nd/banner-3-2.png'
import FloatImage3 from '../../Assets/HomePage_2nd/banner-3-3.png'
import FloatImage4 from '../../Assets/HomePage_2nd/banner-3-4.png'
import NorthEastIcon from '@mui/icons-material/NorthEast';
import VideoIcon from '../../Assets/HomePage_3rd/faq-3-1.png';
import MIcIcon from '../../Assets/HomePage_3rd/faq-3-2.png';
import LaptopIcon from '../../Assets/HomePage_3rd/faq-3.png';
import { ChoosePlan, HomepageGlassCards, fourthLayoutAccord, testimonials, thirdLayoutAccord } from '../../Variables/ProcessVariable';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Footer from '../../Components/Footer/Footer';
import SmartDisplayTwoToneIcon from '@mui/icons-material/SmartDisplayTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Pricing from '../../Assets/Pricing/pricing_icon.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BasicSvgComponent from '../../Components/SVG/BasicSvg';
import StandardSvg from '../../Components/SVG/StandardSvg';
import EnterpriseSvg from '../../Components/SVG/EnterpriseSvg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from 'react-router-dom';
import { get_pricing ,  get_home_section1,  get_home_section2 ,  get_home_section3, get_testimonial, get_faq, get_ai_generate, get_contact_us, add_quote, } from '../../API Service/Api';
import axios from 'axios';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SnackBar from '../../Components/Snackbar';
import CustomTextField from '../../Components/CustomTextField';

export default function HomePage() {

  const [expandedPanels, setExpandedPanels] = useState([]);
  const [data , setData] = useState([]);
  const[mainSection1 , setMainSection1] = useState([]);
  const[mainSection2 , setMainSection2] = useState([]);
  const[mainSection3 , setMainSection3] = useState([]);
  const[mainSection3Accord, setMainSection3Accord] = useState([]);
  const[mainSection4 , setMainSection4] = useState([]);
  const[Testimonials , setTestimonials] = useState([]);
  const[FAQ , setFAQ] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [subscribeType , setSubscribeType] = useState('Monthly');
  const [productPlan , setProductPlan] = useState('POET');
  const [color, setColor] = useState();
  const navigate = useNavigate();
  const [alignment, setAlignment] = React.useState('web');
  const [contact , setContact] = useState([])
  const [errorMsg7, setErrorMsg7] = useState('');
  const [Error7, setError7] = useState(false);
  const [names, setNames] = useState('')
  const [email, setEmail] = useState('')
  const [subject,setSubject]= useState('')
  const[messages , setMessages] = useState('');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setProductPlan(event.target.value);
    getPrincing(subscribeType , event.target.value);
  };



  const togglePanel = (index) => {
    let updatedPanels = [];
    // If the selected panel is already open, close it
    if (expandedPanels.includes(index)) {
      updatedPanels = expandedPanels.filter((item) => item !== index);
    } else {
      // Otherwise, open the selected panel and close others
      updatedPanels = [index];
    }
    setExpandedPanels(updatedPanels);
  };

  const sectionRef = useRef(null);
  const zoomInRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2500,
    arrows:false,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    const animateGrid = (ref, animationClass) => {
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(animationClass);
              observer.unobserve(entry.target);
            }
          });
        });

        observer.observe(ref.current);
      }
    };

    animateGrid(sectionRef, 'animate-section');
    animateGrid(zoomInRef, 'animate-section-zoom');
  }, []);


  const cardRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const animateCards = (refs, animationClass) => {
      refs.forEach((ref, index) => {
        if (ref.current) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add(animationClass);
                observer.unobserve(entry.target);
              }
            });
          });

          observer.observe(ref.current);
        }
      });
    };

    animateCards(cardRefs.slice(0, 2), 'animate-group-1'); 
    animateCards(cardRefs.slice(2, 4), 'animate-group-2');
  }, []);


  const handleClickOpenNameEdit = (title) => {
    const slideCategoryId = title?.replace(/\s+/g, '').toLowerCase();
    navigate(`/poet#${slideCategoryId}`);
  };



  

  const handleClickgotoquote = (section , id) => {
    navigate(section);
    setTimeout(() => {
    const element = document.getElementById(`${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 1000);
}; 

const slideItems = Testimonials && Testimonials.map((i) => {
  return (
    <div className="slide" key={i.name}>
      <Stack spacing={1} p={2}>
        <FormatQuoteIcon sx={{ rotate: '180deg' }} />
        <Box
          display="flex"
          flexDirection={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }}
          justifyContent="space-between"
          alignContent="center"
          gap={{ lg: 0, md: 0, sm: 2, xs: 2 }}
          alignItems="center"
        >
          <Box display="flex" flexDirection="row" gap={2} alignContent="center" alignItems="center">
            <img src={i.images} alt="thumb" style={{ verticalAlign: "middle" }} />
            <Typography>{i.name}</Typography>
          </Box>
          <Rating value={i.star} />
        </Box>
        <Box mt={1}>
          <Typography variant="p" color="#000" fontWeight={500} fontSize="16px" lineHeight={1.2}>
            {i.review}
          </Typography>
        </Box>
      </Stack>
    </div>
  )});


  const getPrincing = (subscribe , plan) => {
    const sendData = new FormData()
    sendData.append('pricingId', '');
    sendData.append('subscriptionType', subscribe);
    sendData.append('productName', plan);
    axios({
      method: 'POST',
      url: get_pricing,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setStatus(false)
        setColor(false)
    
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getPrincing('Monthly' , productPlan);
  }, []);


  const getMainSection1 = () => {
    const sendData = new FormData()
    sendData.append('homeSection1Id', '');
    axios({
      method: 'POST',
      url: get_home_section1,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setMainSection1(res.data.data[0])
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getMainSection1();
  }, []);

  const getMainSection2 = () => {
    const sendData = new FormData()
    sendData.append('homeSection2Id', '');
    axios({
      method: 'POST',
      url: get_home_section2,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setMainSection2(res.data.data[0])
      } else {
        setStatus(false)
        setColor(false)
        
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getMainSection2();
  }, []);

  const getMainSection3 = () => {
    const sendData = new FormData()
    sendData.append('homeSection3Id', '');
    axios({
      method: 'POST',
      url: get_home_section3,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setMainSection3(res.data.data)
        setMainSection3Accord(res.data.data.accordionData)
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getMainSection3();
  }, []);


  const getFAQ = () => {
    const sendData = new FormData()
    sendData.append('faqId', '');
    axios({
      method: 'POST',
      url: get_faq,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setFAQ(res.data.data)
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getFAQ();
  }, []);

  const getTestimonial = () => {
    const sendData = new FormData()
    sendData.append('testimonialId', '');
    axios({
      method: 'POST',
      url: get_testimonial,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setTestimonials(res.data.data)
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getTestimonial();
  }, []);

  const getMainSection4 = () => {
    const sendData = new FormData()
    sendData.append('AIGenerateId', '');
    axios({
      method: 'POST',
      url: get_ai_generate,
      data:sendData
    }).then(res => {
      if (!res.data.error) {

        setStatus(true)
        setColor(true)
        setMainSection4(res.data.data)
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getMainSection4();
  }, []);


  const getContact = () => {
    const sendData = new FormData()
    sendData.append('contactUsId', '');
    axios({
      method: 'POST',
      url: get_contact_us,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setStatus(true)
        setColor(true)
        setContact(res.data.data)
      } else {
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getContact();
  }, []);


  const submitQuery = () =>{
    const sendData = new FormData()
    sendData.append('names', names);
    sendData.append('email', email);
    sendData.append('subjects', subject);
    sendData.append('messages', messages);
    axios({
      method: 'POST',
      url: add_quote,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setNames('')
        setEmail('')
        setMessages('')
        setSubject('');
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }


  const validateInput = () =>{
    if(email !== ''){
    if (/\S+@\S+\.\S+/.test(email)) {
      setErrorMsg7("");
      setError7(false);
      } else {
      setError7(true);
      setErrorMsg7("Invalid Email Address");
      }
    }
    else{
      setError7(true);
      setErrorMsg7("Email Address Cannot Be Empty");
    }
  }
  
  return (
    <div>

      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Box className='Banner'>
      <Header />

      <Container>
      <Stack spacing={2} textAlign='center' mt={7} mb={4}>
      <Typography variant='h2' className="xc-faq-three__img w-img" data-tilt="">
        {
          mainSection1?.title
        }
      </Typography>
      <Typography>
      {
          mainSection1?.content
        }
      </Typography>
      <Box flexDirection='row' gap={5} display='flex' justifyContent='center'>
      <Button variant='contained'        
       sx={{bgcolor:'#135DA8' , borderRadius:'10px', color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}}>
      START A FREE TRIAL  <NorthEastIcon sx={{verticalAlign:'top' , fontSize:'15px', marginLeft:'5px'}} />
      </Button>
      <Button variant='contained'  sx={{bgcolor:'#CECECE' , borderRadius:'10px',  color:'#000' ,fontWeight:600 , textTransform : 'capitalize', ':hover':{bgcolor:'#CECECE'}}}>
      HOW PET WORK <NorthEastIcon sx={{verticalAlign:'top' , fontSize:'15px', marginLeft:'5px'}} />
      </Button> 
      </Box>

      </Stack>
      </Container>

        <Container>
        <Grid container spacing={2} mt={{lg:15 , md:12 , sm:10 , xs:8}}>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
        <img src={FloatImage1} alt='float' className='floatingImage1' />
        <Box textAlign='left'>
        <Typography fontSize='14px' fontWeight={600} sx={{color:'#135DA8'}} mb='10px'>#1 CONTRACTS CREATION PLATFORM</Typography>
        <Typography fontSize={{lg:'54px', md:'50px', sm:'40px', xs:'30px'}} fontWeight={700} lineHeight={1.2} mb='20px'>{mainSection2?.title}</Typography>
        <Stack spacing={2}>
        <Typography><CheckCircleTwoToneIcon sx={{verticalAlign:'middle' , color:'rgba(78, 67, 250, 0.7)'}} /> {mainSection2?.list1}</Typography>
        <Typography><CheckCircleTwoToneIcon sx={{verticalAlign:'middle', color:'rgba(78, 67, 250, 0.7)'}} /> {mainSection2?.list2}</Typography>
        <Typography><CheckCircleTwoToneIcon sx={{verticalAlign:'middle', color:'rgba(78, 67, 250, 0.7)'}} /> {mainSection2?.list3}</Typography>
        <Box>
        <Button variant='contained' 
        sx={{bgcolor:'#135DA8' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
        GET A DEMO
        </Button>
        </Box>
        </Stack>
        </Box>
        <img src={FloatImage2} alt='float' className='floatingImage2' />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative' mt={{lg:0, md:0 , sm:3 , xs:4}}>
        <img src={FloatImage3} alt='float' className='floatingImage3' />
        <Box className='demoImageContainer'>
        <img src={mainSection2?.images} alt='demo' className='demoImage' />
        </Box>
        <img src={FloatImage4} alt='float' className='floatingImage4' />
        </Grid>
        </Grid>   
        </Container>


    <Container ref={sectionRef} className='slideInUp'> 
      <Box className='thirdLayout'>
      <Typography variant='h3' className='thridLayoutTitle1'>
        Contract Making As Easy
      </Typography>
      <Typography variant='h3' className='thridLayoutTitle2'>
        As Uploading Content
      </Typography>

    <Grid container mt={2} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} position='relative'>
            <img src={MIcIcon} alt='float' className='floatingMic' />
              <Box className="xc-faq-three__img w-img" data-tilt="">
              <img src={mainSection3?.images} style={{ width: '100%', verticalAlign: "middle"}} alt='demo' className='laptopImage' />
              </Box>
              <img src={VideoIcon} alt='float' className='floatingVideo' />
              </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} display='flex' justifyContent='center'>
          <Box>
                    {mainSection3Accord.map((faq, index) => (
                        <Accordion
                            key={index}
                            expanded={expandedPanels.includes(index)}
                            onChange={() => togglePanel(index)}
                            sx={{ boxShadow: 2,
                             border: 'none' , 
                             marginBottom:3,
                             '&.Mui-expanded': {
                              borderLeft: '4px solid #135DA8',
                            },
                             maxWidth:600 ,
                            }} 
                            disableGutters
                        >
                            <AccordionSummary 
                             sx={{
                              color: expandedPanels.includes(index) ? '#135DA8' : 'inherit',
                             
                            }}
                            >
                              {index === 0 ? (
                                <SmartDisplayTwoToneIcon sx={{ verticalAlign: 'middle', color: '#135DA8' }} fontSize='large' />
                                ) : index === 1 ? (
                                <AssignmentTwoToneIcon sx={{ verticalAlign: 'middle', color: '#135DA8' }} fontSize='large' />
                                ) : (
                                <DriveFileRenameOutlineTwoToneIcon sx={{ verticalAlign: 'middle', color: '#135DA8' }} fontSize='large' />
                                )}

                              <Box px={3}>
                                <Typography variant='h4' fontWeight={600} className='acccordTitle'>
                                {faq.title}
                                </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box textAlign='left' px={8}>
                                    <ul>
                                        <Stack spacing={1} mt={1}>
                                            <Typography component='p' sx={{ color:'#475467'}}>{faq.content}</Typography>
                                        </Stack>
                                    </ul>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}


                    </Box>
          </Grid>
    
    </Grid>
      </Box>
    </Container>

      <Box  className='Banner2' textAlign='center'>
      <Typography variant='h3' className='Banner2Title' >AI Generate Contents In Seconds</Typography>
      <Typography px={1} >AI has a variety of features that make it the best place to create contents
      </Typography>
      <Container >
      <Grid container mb={5} spacing={3} mt={4}  ref={zoomInRef}>
      {
      mainSection4.map((i , index)=>{ // Define border colors for even and odd items
      return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
      <Box className='GlassCards' onClick={()=>handleClickOpenNameEdit(i.redirect)}>
      <img src={i.images} alt={i.icon} className='glassImage' />
      <Typography variant='h3' className='glassTitle'>{i.title}</Typography>
      <Typography variant='body1' className='glassContent'>{i.content}</Typography>
      </Box>
      </Grid>
      )
      })
      }
      </Grid>
      </Container>
      </Box>

      <Container>
      <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
      <Typography variant='h2' className='Banner2Title'>
      Money well invested
      </Typography>
      <Box display='flex' justifyContent='end' p={1}  id='priceSection'>
      <ToggleButtonGroup
      color="primary"
      value={productPlan}
      exclusive
      variant='filled'
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="POET" sx={{ '&.Mui-selected': { backgroundColor: '#135AD8', color:'#fff' } }} >POET</ToggleButton>
      <ToggleButton value="CONTRACT - PRO" sx={{ '&.Mui-selected': { backgroundColor: '#135AD8', color:'#fff' } }}>CONTRACT-PRO</ToggleButton>
    </ToggleButtonGroup>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='center' gap={5} mt={3}>
      <Button 
      variant='contained' 
      onClick={()=>{
        setSubscribeType('Monthly')
        getPrincing('Monthly', productPlan)
      }}
      sx={{bgcolor: subscribeType === 'Monthly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
      Monthly
      </Button>
      <Button 
      onClick={()=>{
        setSubscribeType('Yearly')
        getPrincing('Yearly', productPlan)
      }}
      variant='contained' 
       sx={{bgcolor: subscribeType === 'Yearly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
        Yearly
      </Button>
      </Box>

      <Grid container mt={{lg:2 , md:2 , sm:2 , xs:1}} spacing={4} mb={10} zIndex={100} justifyContent='center'  className='pricingGrid'>
      {data?.map((plan, index) => {
      const planName = Object.keys(plan)[0];
      const planData = plan[planName];
      return (
      <Grid key={index} item xs={12} sm={6} md={3} lg={3} className='pricingCard' ref={cardRefs[index]}>
      <Box position='relative'>
      {
        index !== 1 ? <BasicSvgComponent /> : <StandardSvg />
      }
      <Box 
       className='pricingBox'
       width='100%' p={2}
      >
      <Box display='flex' justifyContent='start'>
      <Typography variant='h3' className='serviceName' color='#fff' fontSize={{lg:'24px', md:'21px', sm:'18px', xs:'18px'}}>
      {planName}
      </Typography>
      </Box>

      <Box textAlign='center' className='cost'>
      <Typography variant='h5' color='#fff' className='serviceName'>${planData.cost} <span style={{fontSize:'12px'}}>/month</span></Typography>
      </Box>

      <Box className='servicelistBox' textAlign='left'>
      {planData?.services?.map((service, serviceIndex) => (
      <Typography className='serviceList' key={serviceIndex} color='#fff'>
      <CheckCircleIcon sx={{ verticalAlign: 'middle', mr: 2 }} />
      {service?.serviceName}
      </Typography>
      ))}
      <Box>
      <Button variant='contained' fullWidth textTransform='uppercase' sx={{':hover':{bgcolor:'#ffff'}, color:'#000' , borderRadius: '10px', fontWeight:600 , marginRight:'15px' , bgcolor:'#fff'}}>
      Choose Plan
      </Button>
      </Box>
      </Box>
    
      </Box>
      </Box>
      </Grid>
      );
      })}
      </Grid>
      </Box>
      </Container>
      
        <Box mt={8}>
          <Box py={3}>
          <Typography variant='h3' className='Banner2Title' >Our Happy Customers</Typography>
          </Box>
          <Slider {...settings}>
          {slideItems}
          </Slider>
          </Box>


        <Box mt={{lg:10 , md:7 , sm:5 , xs:3}}>
        <Container>
        <Box>
        <Typography variant='h3' className='Banner2Title' >Frequently Asked Questions</Typography>
        <Grid container mt={2} spacing={4} justifyContent='space-between'>
        <Grid item xs={12} sm={12} md={5} lg={5} position='relative' display='flex' flexDirection='column' justifyContent='start'>
        <Stack spacing={3} textAlign='left'>
        <Typography variant='p' fontWeight={700} fontSize='48px' textTransform='capitalize' lineHeight='72px'>Any <span className='FAQText1'>Questions?</span></Typography>
        <Typography  variant='p' fontWeight={700} fontSize='40px' textTransform='capitalize' >We Have Answers!</Typography>
        <Typography variant='p' color='#475467' width={250}>Don’t find your answer here? just send us a message for any query.</Typography>
        <Button onClick={()=>handleClickgotoquote('/#QuoteSection', "QuoteSection")} variant='contained' sx={{ width :'25ch', borderRadius: '10px', fontWeight:600 , marginRight:'15px' , bgcolor:'#135DA8'}}>
         Connect with us
        </Button>
        </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={7}  id='FAQSection'>
        <Box>
        {FAQ.map((faq, index) => (
       <Accordion
       key={index}
       expanded={expandedPanels.includes(index)}
       onChange={() => togglePanel(index)}
       sx={{ boxShadow: 2,
        border: 'none' , 
        background:'#BBC8D7',
        marginBottom:3,
        '&.Mui-expanded': {
         borderLeft: '4px solid #135DA8',
         background:'#fff',
       },
       }} 
   >
       <AccordionSummary 
        sx={{
         color: expandedPanels.includes(index) ? '#135DA8' : 'inherit',
        
       }}
       >
          <Box display='flex' textAlign='left' justifyContent='space-between' width='100%'>
          <Typography variant='h6' fontWeight={550} className='acccordTitle'>
          {faq.title}
          </Typography>
          <ExpandMoreIcon sx={{fontSize:'40px' , verticalAlign:'top', my:-0.5}} />
          </Box>
       </AccordionSummary>
       <AccordionDetails>
           <Box textAlign='left' px={8}>
               <ul>
                   <Stack spacing={1} mt={1}>
                       <Typography component='p' sx={{ color:'#475467'}}>{faq.content}</Typography>
                   </Stack>
               </ul>
           </Box>
       </AccordionDetails>
   </Accordion>
        ))}


        </Box>
        </Grid>

        </Grid>
        </Box>
        </Container>

        </Box>


        <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
        <Container>
        <Box   id='QuoteSection'>
        <Typography variant='h3' className='Banner2Title' >GET A QUOTE</Typography>
        <Grid container mt={2} spacing={4} justifyContent='space-between'>
        <Grid item xs={12} sm={12} md={5} lg={5} position='relative' display='flex' flexDirection='column' justifyContent='start'>
        <Stack spacing={1} textAlign='left'>
        <Typography  variant='p' fontWeight={700} fontSize='40px' textTransform='capitalize' className='FAQText1'>Get In Touch With Us</Typography>
        <Box display='flex' flexDirection='column' gap={2} p={2}>
        <Typography variant='p' color='#475467' sx={{wordWrap:'break-word' , wordBreak:'break-word'}}> <strong>Address : </strong>{contact?.contactAddress}</Typography>
        <Typography variant='p' color='#475467' > <strong>Phone :</strong> {contact?.contactPhone}</Typography>
        <Typography variant='p' color='#475467' > <strong>Email :</strong> {contact?.contactMail}</Typography>
        <Box py={1}>
        <a style={{ textDecoration: 'none', cursor: 'pointer' ,fontWeight:600 ,  color: '#135DA8' }} href={contact?.mapLink} target="_blank" rel="noopener">View on Google Maps</a>
        </Box>
        </Box>
        </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box>
            <Grid container spacing={2}>

            <Grid item xs={12}>
            <CustomTextField
            onChange={setNames}
            label="Name" 
            value={names}
            validationRegex={/^[A-Za-z]+$/}
            errorMessage={"Forbidden character: %<>$'\""}
            />
            </Grid>

              <Grid item xs={12}>
              <TextField 
              id="outlined-basic"
               label="Email" 
               variant="outlined"
               type='email'
               size='small'
               helperText={errorMsg7}
               error={Error7}
               onChange={(e)=>setEmail(e.target.value)}
               value={email}
               onBlur={validateInput}
                fullWidth />
              </Grid>

              <Grid item xs={12}>
              <CustomTextField
            onChange={setSubject}
            value={subject}
            label="Subject" 
            multiline={true}
            rows={2}
            validationRegex={''}
            errorMessage={""}
            />
              </Grid>

              <Grid item xs={12}>
              <CustomTextField
            onChange={setMessages}
            value={messages}
            multiline={true}
            rows={4}
            label="Message" 
            validationRegex={''}
            errorMessage={""}
            />
              </Grid>

              <Grid item xs={12}>
              <Button 
              variant='contained' 
              fullWidth
              onClick={submitQuery}
              sx={{bgcolor:'#135DA8' , borderRadius:'10px', color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
              Submit
              </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        </Grid>
        </Box>
        </Container>

        </Box>



        
      </Box>
      <Footer />
    </div>
  )
}
