import React, { useEffect, useRef, useState } from 'react'
import './POET.css';
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary, Divider, Rating, Card, CardContent,} from '@mui/material'
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import fetaureImage from '../../../Assets/featureImages.png';
import featureboxImg from '../../../Assets/featurebox.png';
import grammercheck from '../../../Assets/POET/grammer.jpg'
import rewrite from '../../../Assets/POET/rewrite.jpg';
import plagarism from '../../../Assets/POET/plagarism.jpg';
import realtime from '../../../Assets/POET/realtime.jpg';
import coauthor from '../../../Assets/POET/coauthor.jpg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ChoosePlan } from '../../../Variables/ProcessVariable';
import axios from 'axios';
import { get_pricing, get_product } from '../../../API Service/Api';
import BasicSvgComponent from '../../../Components/SVG/BasicSvg';
import StandardSvg from '../../../Components/SVG/StandardSvg';
import Logo from '../../../Assets/Logos/logo7-removebg-preview.png';

export default function POET() {
  const [data , setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [plans , setPlan] = useState([]);
  const gridRefRight1 = useRef(null);
  const gridRefLeft1 = useRef(null);
  const gridRefRight2 = useRef(null);
  const gridRefLeft2 = useRef(null);
  const gridRefRight3 = useRef(null);
  const gridRefLeft3 = useRef(null);
  const gridRefRight4 = useRef(null);
  const gridRefLeft4 = useRef(null);
  const gridRefRight5 = useRef(null);
  const gridRefLeft5 = useRef(null);
  const gridRefRight6 = useRef(null);
  const gridRefLeft6 = useRef(null);
  const gridRefRight7 = useRef(null);
  const gridRefLeft7 = useRef(null);
  const [subscribeType , setSubscribeType] = useState('Monthly');

  useEffect(() => {
    const animateGrid = (ref, animationClass) => {
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(animationClass);
              observer.unobserve(entry.target);
            }
          });
        });

        observer.observe(ref.current);
      }
    };

    animateGrid(gridRefRight1, 'animate-slide-right');
    animateGrid(gridRefLeft1, 'animate-slide-left');
    animateGrid(gridRefRight2, 'animate-slide-right');
    animateGrid(gridRefLeft2, 'animate-slide-left');
    animateGrid(gridRefRight3, 'animate-slide-right');
    animateGrid(gridRefLeft3, 'animate-slide-left');
    animateGrid(gridRefRight4, 'animate-slide-right');
    animateGrid(gridRefLeft4, 'animate-slide-left');
    animateGrid(gridRefRight5, 'animate-slide-right');
    animateGrid(gridRefLeft5, 'animate-slide-left');
    animateGrid(gridRefRight6, 'animate-slide-right');
    animateGrid(gridRefLeft6, 'animate-slide-left');
    animateGrid(gridRefRight7, 'animate-slide-right');
    animateGrid(gridRefLeft7, 'animate-slide-left');
  }, []);


  const cardRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const animateCards = (refs, animationClass) => {
      refs.forEach((ref, index) => {
        if (ref.current) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add(animationClass);
                observer.unobserve(entry.target);
              }
            });
          });

          observer.observe(ref.current);
        }
      });
    };

    animateCards(cardRefs.slice(0, 2), 'animate-group-1'); 
    animateCards(cardRefs.slice(2, 4), 'animate-group-2');
  }, []);

    
  const getAllData = () => {
    const sendData = new FormData()
    sendData.append('productId', '');
    sendData.append('productName', 'POET');
    axios({
      method: 'POST',
      url: get_product,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);


  const Feature = ({ title, content, image, tryButtonLabel , index}) => (
   <>
   {
    index % 2 === 0 ?
    <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
    <Grid item xs={12} md={6} sm={6} lg={6}  
    order={{lg:1 , md:1 , sm: 1 , xs: 2}} 
    display='flex' justifyContent='center' ref={gridRefRight1} id={title.replace(/\s+/g, '').toLowerCase()}
    >
        <Box className='featurebox' position='relative'>
          <img src={featureboxImg} alt='feature' className='featureImagebox' />
          <Box className='contentBox'>
          <Typography variant='h4' fontWeight={600} className='title'>{title}</Typography>
          <Typography variant='subtitle2' fontWeight={550} className='content'>
          {content}
          </Typography>
                            
          </Box>
          <Box className='circlenumber'>
           {index + 1}
        </Box>
        </Box>
    </Grid>

    <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:2 , md:2 , sm: 2 , xs: 1}} ref={gridRefLeft1} display='flex' justifyContent='center'>
      <Box className='ImageBox'>
       <img src={image} alt='feature' className='featureImage' />
       <Box className='tryButton'>
       <Button variant='contained'        
       sx={{bgcolor:'#8DA5DB' , borderRadius:'10px', color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
       TRY ON
      </Button>
      </Box>
      </Box>
      </Grid>
      </Grid>
      :

      <Grid container spacing={{lg:3 , md:2 , sm:1 , xs:1}} rowSpacing={{lg:4 , md:3 , sm:2 , xs:1}}>
            <Grid item xs={12} md={6} sm={6} lg={6} order={{lg:1 , md:1 , sm: 1 , xs: 1}} ref={gridRefRight2} display='flex' justifyContent='center'>
            <Box className='ImageBox'>
             <img src={image} alt='feature' className='featureImage' />
             <Box className='tryButton'>
             <Button variant='contained'        
             sx={{bgcolor:'#8DA5DB' , borderRadius:'10px', color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
             TRY ON
            </Button>
            </Box>
            </Box>
            </Grid>

          <Grid item xs={12} md={6} sm={6} lg={6}  
          order={{lg:2 , md:2 , sm: 2 , xs: 2}} 
          display='flex' justifyContent='center' ref={gridRefLeft2} id={title.replace(/\s+/g, '').toLowerCase()}
          >
              <Box className='featurebox' position='relative'>
                <img src={featureboxImg} alt='feature' className='featureImagebox' />
                <Box className='contentBox'>
                <Typography variant='h4' fontWeight={600} className='title'>{title}</Typography>
                <Typography variant='subtitle2' fontWeight={550} className='content'>
                {content}
                </Typography>
                                  
                </Box>
                <Box className='circlenumber'>
                {index + 1}
              </Box>
              </Box>
          </Grid>
            </Grid>
   }
   </>
  );
  
  const getPrincing = (subscribe) => {
    const sendData = new FormData()
    sendData.append('pricingId', '');
    sendData.append('subscriptionType', subscribe);
    sendData.append('productName', 'POET');
    axios({
      method: 'POST',
      url: get_pricing,
      data:sendData
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setPlan(res.data.data)
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  useEffect(() => {
    getPrincing('Monthly');
  }, []);

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }, []);

  return (
    <div>
      <Box className='Banner'>
      <Header />


      <Container>
      <Stack spacing={2} textAlign='center' mt={6} mb={4}>
        <Box display='flex' flexDirection='row' justifyContent='center'>
        <Typography variant='h3'>
        POET
      </Typography>
      <img  src={Logo} alt='header' style={{objectFit:'contain' , width:80 , height:80 , rotate:'45deg' ,verticalAlign:'middle'}} className='pen' />
        </Box>
      <Typography>
      POET is an innovative tool designed to streamline your work processes and boost productivity. It's loaded with a wide range of features aimed at making your daily tasks easier and more efficient
      </Typography>
      <Box display='flex' justifyContent='center'>
      <Button variant='contained'        
       sx={{bgcolor:'#135DA8' , borderRadius:'10px', color:'#fff' , fontWeight:600 , textTransform : 'capitalize'}}>
       TRY POET  <NorthEastIcon sx={{verticalAlign:'top' , fontSize:'15px', marginLeft:'5px'}} />
      </Button>
      </Box>
      </Stack>
      </Container>

      <Box mt={{ lg: 15, md: 10, sm: 8, xs: 6 }} mb={5}>
      <Container>
        <Stack spacing={{ lg: 8, md: 5, sm: 4, xs: 3 }} justifyContent='center'>
          {data.map((feature, index) => (
            <Feature
              key={index}
              index={index}
              title={Object.keys(feature)[0]}
              content={feature[Object.keys(feature)[0]].content}
              image={feature[Object.keys(feature)[0]].image}
              tryButtonLabel='TRY ON'
            />
          ))}
        </Stack>
      </Container>
    </Box>


    <Container>
      <Box mt={{lg:10 , md:8 , sm:6 , xs:4}}>
      <Typography variant='h2' className='Banner2Title'>
       Pricing Plan
      </Typography>
      <Box display='flex' flexDirection='row' justifyContent='center' gap={5} mt={3}>
      <Button 
      variant='contained' 
      onClick={()=>{
        setSubscribeType('Monthly')
        getPrincing('Monthly')
      }}
      sx={{bgcolor: subscribeType === 'Monthly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
      Monthly
      </Button>
      <Button 
      onClick={()=>{
        setSubscribeType('Yearly')
        getPrincing('Yearly')
      }}
      variant='contained' 
       sx={{bgcolor: subscribeType === 'Yearly' ? '#135DA8' : '#CECECE' , borderRadius:'10px',  width:'20ch' , color:'#fff' , fontWeight:550 , textTransform : 'capitalize'}}>
        Yearly
      </Button>
      </Box>

      <Grid container mt={{lg:2 , md:2 , sm:2 , xs:1}} spacing={4} mb={10} zIndex={100} justifyContent='center'  className='pricingGrid'>
      {plans?.map((plan, index) => {
      const planName = Object.keys(plan)[0];
      const planData = plan[planName];
      return (
      <Grid key={index} item xs={12} sm={6} md={3} lg={3} className='pricingCard' ref={cardRefs[index]}>
      <Box position='relative'>
      {
        index !== 1 ? <BasicSvgComponent /> : <StandardSvg />
      }
      <Box 
       className='pricingBox'
       width='100%' p={2}
      >
      <Box display='flex' justifyContent='start'>
      <Typography variant='h3' className='serviceName' color='#fff' fontSize={{lg:'24px', md:'21px', sm:'18px', xs:'18px'}}>
      {planName}
      </Typography>
      </Box>

      <Box textAlign='center' className='cost'>
      <Typography variant='h5' color='#fff' className='serviceName'>${planData.cost} <span style={{fontSize:'12px'}}>/month</span></Typography>
      </Box>

      <Box className='servicelistBox' textAlign='left'>
      {planData?.services?.map((service, serviceIndex) => (
      <Typography className='serviceList' key={serviceIndex} color='#fff'>
      <CheckCircleIcon sx={{ verticalAlign: 'middle', mr: 2 }} />
      {service?.serviceName}
      </Typography>
      ))}
      <Box>
      <Button variant='contained' fullWidth textTransform='uppercase' sx={{':hover':{bgcolor:'#ffff'}, color:'#000' , borderRadius: '10px', fontWeight:600 , marginRight:'15px' , bgcolor:'#fff'}}>
      Choose Plan
      </Button>
      </Box>
      </Box>
    
      </Box>
      </Box>
      </Grid>
      );
      })}
      </Grid>
      </Box>
      </Container>


      </Box>



      <Footer />
    </div>
  )
}
