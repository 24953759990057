import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BiLogoTwitter } from "react-icons/bi";
import { BiLogoLinkedin } from "react-icons/bi";
import { AiFillYoutube } from "react-icons/ai";
import './Footer.css';
import Logo from '../../Assets/Group+571.png'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Footer() {

  const navigate = useNavigate();

  const location = useLocation();

  const handleClickOpenNameEdit = (id) => {
    navigate(`/#${id}`);
    setTimeout(() => {
    const element = document.getElementById(`${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth"  });
    }
  }, location.pathname === '/' ? 0 : 2000);
  }; 



  return (
    <Box className='Footer' mt={{lg:30, md:30 , sm:20, xs:20}}  position='relative'>
      <Box p={5}>
        <Container>
        <Grid container mt={{lg:25, md:25 , sm:20, xs:20}} spacing={2} justifyContent='center'>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Stack spacing={3} textAlign='left' mb={{lg:0 , md:0, sm: 2 , xs: 3}}>
            <Link href="/" className="header__content__logo">
           <img src={Logo} alt='header' />
            </Link>
          <Box display='flex' flexDirection='row' gap={3}>
            <BiLogoFacebook style={{verticalAlign: 'middle' , color:'#ffffff' , cursor:'pointer'}} />
            <BiLogoInstagramAlt style={{verticalAlign: 'middle' , color:'#ffffff', cursor:'pointer'}}/>
            <BiLogoTwitter style={{verticalAlign: 'middle' , color:'#ffffff', cursor:'pointer'}}/>
            <BiLogoLinkedin style={{verticalAlign: 'middle' , color:'#ffffff', cursor:'pointer'}}/>
            <AiFillYoutube style={{verticalAlign: 'middle' , color:'#ffffff', cursor:'pointer'}} />
          </Box>
            <Typography color='#ffffff'>© PET 2023. All Rights Reserved.</Typography>          
          </Stack>
          </Grid>      


          <Grid item xs={12} sm={6} md={3} lg={3}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' , textAlign:'left'}}>
           <Typography variant="h3" color="#FFDF15" fontWeight={500} fontSize={21} marginBottom='20px'>Product</Typography>
            <li><Link href="/poet" underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>POET</Typography></Link></li>
            <li><Link href="/contract" underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Contract-Pro</Typography></Link></li>                        </ul>
          </Grid>    


          <Grid item xs={12} sm={6} md={3} lg={3}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' , textAlign:'left'}}>
          <Typography variant="h3" color="#FFDF15" fontWeight={500} fontSize={21}  marginBottom='20px'>Company</Typography>
          <li><Link href="/aboutus" underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>About Us</Typography></Link></li>
          <li><Link onClick={()=>handleClickOpenNameEdit("priceSection")}  underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Pricing Plans</Typography></Link></li>           
          </ul>

          </Grid> 


          <Grid item xs={12} sm={6} md={3} lg={3}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' , textAlign:'left'}}>
          <Typography variant="h3" color="#FFDF15" fontWeight={500} fontSize={21}  marginBottom='20px'>Support</Typography>
          <li><Link onClick={()=>handleClickOpenNameEdit("FAQSection")} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>FAQ</Typography></Link></li>
          <li><Link onClick={()=>handleClickOpenNameEdit("QuoteSection")} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Contact Us</Typography></Link></li>            
          </ul>
          </Grid> 
          </Grid>                
        </Container>
        
        <Container>
        <Box className='TopBox' padding={{lg:'70px 100px 70px 100px' , md:'70px 100px 70px 100px' , sm:5 , xs:5 }}>
        <div className="snow"></div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack spacing={{lg:2 , md:2 , sm:1 , xs:1}} textAlign='left'>
            <Typography variant='h3' className='topBoxText' fontSize={{lg:'40px', md:'40px', sm:'30px', xs:'30px'}}>
             The #1 rated AI
             </Typography>
             <Typography variant='h3' className='topBoxText' fontSize={{lg:'40px', md:'40px', sm:'30px', xs:'30px'}}>
             contract creation
             </Typography>
             <Typography variant='h3' className='topBoxText' fontSize={{lg:'40px', md:'40px', sm:'30px', xs:'30px'}}>
             platform
             </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} display='flex' alignContent='center' alignItems='center' justifyContent={{lg:'end', md:'end', sm:'start', xs:'start'}}>
          <Button variant='contained' sx={{bgcolor:'#135DA8', borderRadius:'10px' , padding:'10px 20px', fontSize:'14px', fontWeight:600}}>
              Get Started
          </Button>
        </Grid>
        </Grid>
        </Box>
        </Container>

      </Box>

        <Grid container mt={{lg:5 , md:5, sm:3 , xs:2}} p={1} spacing={1}>
        <Grid item xs={12} md={12} textAlign='center' justifyContent='center' display='flex'>
        <Typography className='copyright'>
        © PET {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
        </Grid>
        {/* <Grid item xs={12} md={6} textAlign='center' px={2} justifyContent={{lg:'end', md:'end', sm:'center', xs:'center'}} display='flex'>
        <Link href='https://www.mdqualityapps.com/' underline="none" target="_blank" rel="noopener">
            <Typography sx={{color:"#FFDF15"}}> Designed by  MDQuality Apps Solutions</Typography>
            </Link>
        </Grid> */}
        </Grid>

    </Box>
  )
}
